import React from "react";
import {
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
} from "@mui/material";

import useDebouncedEffect from "../../hooks/useDebouncedEffect";

interface PlatformSettingsNumberFieldProps {
    unit: string;
    label: string;
    labelPlacement?: "bottom" | "top" | "end" | "start";
    value: number;
    onChange: (number: number) => void;
}

const PlatformSettingsNumberField: React.FC<
    PlatformSettingsNumberFieldProps
> = ({ unit, label, labelPlacement = "end", value, onChange }) => {
    const [number, setNumber] = React.useState(value);

    // Leave a 1-second delay before calling the onChange function to allow for the user to change the value
    useDebouncedEffect(
        () => {
            // This gets triggered when the value first gets set so need to disable it here
            if (number !== value) onChange(number);
        },
        [number],
        1000
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNumber(Number(event.target.value));
    };

    return (
        <>
            <FormGroup sx={{ paddingTop: "20px", alignItems: "flex-start" }}>
                <FormControlLabel
                    labelPlacement={labelPlacement}
                    sx={{ marginLeft: "0" }}
                    control={
                        <TextField
                            value={number}
                            onChange={handleChange}
                            type="number"
                            label={label}
                        />
                    }
                    label={
                        <Typography
                            sx={{
                                padding:
                                    labelPlacement === "end"
                                        ? "0 0 0 10px"
                                        : "0 10px 0 0",
                            }}
                            variant="subtitle1"
                        >
                            {unit}
                        </Typography>
                    }
                />
            </FormGroup>
        </>
    );
};

export default PlatformSettingsNumberField;
