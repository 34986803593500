export enum ServiceKeys {
    PlatformSettings = "platformSettings",
    Auth = "auth",
    User = "user",
    AllUsers = "allUsers",
    CustomProperties = "customProperties",
    ProvidersDefinitions = "providersDefinitions",
    Providers = "providers",
    UiConfig = "uiConfig",
    UiConfigRenames = "uiConfigRenames",
}

export default ServiceKeys;
