import React, { useState } from "react";
import {
    FormControlLabel,
    FormGroup,
    Skeleton,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface PlatformSettingsSwitchProps {
    title: string;
    subtitle: string;
    loading: boolean;
    value?: boolean;
    disabled?: boolean;
    disabledTooltip?: string;
    onChange?: (checked: boolean) => void;
    paddingTop?: string | number;
}

const PlatformSettingsTitle: React.FC<PlatformSettingsSwitchProps> = ({
    title,
    subtitle,
    loading,
    value,
    disabled,
    disabledTooltip,
    onChange,
    children,
    paddingTop = "40px",
}) => {
    const theme = useTheme();

    const [checked, setChecked] = useState(value);

    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        if (onChange) onChange(event.target.checked);
    };

    if (onChange) {
        return (
            <>
                <FormGroup sx={{ padding: `${paddingTop} 0 10px` }}>
                    {disabled ? (
                        <Tooltip
                            title={disabledTooltip || ""}
                            placement="top-start"
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disabled}
                                        checked={checked}
                                        onChange={handleSwitch}
                                    />
                                }
                                label={
                                    <Typography variant="h2">
                                        {title}
                                    </Typography>
                                }
                            />
                        </Tooltip>
                    ) : (
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={loading}
                                    checked={checked}
                                    onChange={handleSwitch}
                                />
                            }
                            label={
                                <Typography variant="h2">{title}</Typography>
                            }
                        />
                    )}
                </FormGroup>
                <Typography
                    variant="subtitle1"
                    color={theme.palette.text.secondary}
                >
                    {subtitle}
                </Typography>
                {loading ? <Skeleton>{children}</Skeleton> : children}
            </>
        );
    }

    return (
        <>
            <Typography variant="h2" sx={{ padding: "40px 0 10px" }}>
                {title}
            </Typography>
            <Typography
                variant="subtitle1"
                color={theme.palette.text.secondary}
            >
                {subtitle}
            </Typography>
            {loading ? <Skeleton>{children}</Skeleton> : children}
        </>
    );
};

export default PlatformSettingsTitle;
