import React from "react";
import { useTheme } from "@mui/material/styles";
import { Stack, Tooltip, Typography } from "@mui/material";

import PlatformSettingsTitle from "./PlatformSettingsTitle";
import PlatformSettingsNumberField from "./PlatformSettingsNumberField";
import PlatformSettingsRadioButtons from "./PlatformSettingsRadioButtons";
import useGetPlatformSettings from "../../hooks/query/platformSettings.ts/useGetPlatformSettings";
import useUpdatePlatformSettingsMutation from "../../hooks/query/platformSettings.ts/useUpdatePlatformSettingsMutation";

const companyUniquenessOptions = [
    { value: "domain", label: "Website Domain" },
    { value: "duedil_id", label: "Duedil ID" },
];

const navigationPreferences = [
    { value: "responsive", label: "Responsive" },
    { value: "side", label: "Side" },
];

const dashboardTemplate = [
    { value: "default", label: "Default" },
    { value: "news_overview", label: "News Overview" },
];

const PlatformSettings = () => {
    const theme = useTheme();

    const { data: platformSettingsData, isLoading } = useGetPlatformSettings();
    const { mutate: mutatePlatformSetting } =
        useUpdatePlatformSettingsMutation();

    return (
        <>
            <Typography variant="h1" textTransform="uppercase">
                Platform Settings
            </Typography>
            <Typography
                variant="subtitle1"
                color={theme.palette.text.secondary}
                paddingTop="10px"
            >
                Configure settings for login, company identification, scraping
                options and data retention, and others
            </Typography>
            <PlatformSettingsTitle
                title="Company Uniqueness"
                subtitle="Set how a company's Uniqueness is defined, either by website or by DueDil ID"
                loading={isLoading}
            >
                <PlatformSettingsRadioButtons
                    options={companyUniquenessOptions}
                    value={
                        platformSettingsData?.company_uniqueness_column ||
                        "domain"
                    }
                    onChange={(value) =>
                        mutatePlatformSetting({
                            field: "company_uniqueness_column",
                            value,
                        })
                    }
                />
            </PlatformSettingsTitle>
            <PlatformSettingsTitle
                title="Navigation Preference"
                subtitle="Determines whether to display a responsive menu that switches from a 'top' to 'side' (mobile-friendly) system when the viewport narrows or a persistent side menu regardless of viewport width"
                loading={isLoading}
            >
                <PlatformSettingsRadioButtons
                    options={navigationPreferences}
                    value={
                        platformSettingsData?.navigation_preference ||
                        "responsive"
                    }
                    onChange={(value) =>
                        mutatePlatformSetting({
                            field: "navigation_preference",
                            value,
                        })
                    }
                />
            </PlatformSettingsTitle>
            <PlatformSettingsTitle
                title="Dashboard Template"
                subtitle="Determines whether to display the default dashboard page or the new news overview page"
                loading={isLoading}
            >
                <PlatformSettingsRadioButtons
                    options={dashboardTemplate}
                    value={
                        platformSettingsData?.dashboard_template || "default"
                    }
                    onChange={(value) =>
                        mutatePlatformSetting({
                            field: "dashboard_template",
                            value,
                        })
                    }
                />
            </PlatformSettingsTitle>
            <PlatformSettingsTitle
                title="Query Timeout"
                subtitle="Set how long to allow any query on the platform to run before timing out. Note: Filters have a longer timeout than the one specified here"
                loading={isLoading}
            >
                <PlatformSettingsNumberField
                    unit="Milliseconds"
                    label="Time"
                    value={platformSettingsData?.query_timeout || 0}
                    onChange={(number) =>
                        mutatePlatformSetting({
                            field: "query_timeout",
                            value: number,
                        })
                    }
                />
            </PlatformSettingsTitle>
            <PlatformSettingsTitle
                title="Company Scraping from Website Interval"
                subtitle="How often to check a company's website for new information on a company"
                loading={isLoading}
            >
                <PlatformSettingsNumberField
                    unit="Days"
                    label="Time"
                    value={platformSettingsData?.website_scrape_interval || 0}
                    onChange={(number) =>
                        mutatePlatformSetting({
                            field: "website_scrape_interval",
                            value: number,
                        })
                    }
                />
            </PlatformSettingsTitle>
            <PlatformSettingsTitle
                title="Company Recommendations Check Interval"
                subtitle="How often an ML model is run to decide whether or not to recommend a company"
                loading={isLoading}
            >
                <PlatformSettingsNumberField
                    unit="Days"
                    label="Time"
                    value={
                        platformSettingsData?.recommendation_check_interval || 0
                    }
                    onChange={(number) =>
                        mutatePlatformSetting({
                            field: "recommendation_check_interval",
                            value: number,
                        })
                    }
                />
            </PlatformSettingsTitle>
            <PlatformSettingsTitle
                title="Filter Cache Counts Check Interval"
                subtitle="How often to check if a filter's cached count is out of date"
                loading={isLoading}
            >
                <PlatformSettingsNumberField
                    unit="Minutes"
                    label="Time"
                    value={
                        platformSettingsData?.max_filter_cache_counts_age_minutes ||
                        0
                    }
                    onChange={(number) =>
                        mutatePlatformSetting({
                            field: "max_filter_cache_counts_age_minutes",
                            value: number,
                        })
                    }
                />
            </PlatformSettingsTitle>
            <PlatformSettingsTitle
                title="Data Retention"
                subtitle="How long to retain data in the system"
                loading={isLoading}
            >
                <Stack
                    justifyItems="space-between"
                    direction="row"
                    alignItems="baseline"
                >
                    <Tooltip
                        placement="top"
                        title="The maximum number of months to retain news articles"
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{ paddingRight: "200px" }}
                        >
                            Articles
                        </Typography>
                    </Tooltip>
                    <PlatformSettingsNumberField
                        unit="Months"
                        label="Time"
                        value={
                            platformSettingsData?.max_article_age_months || 0
                        }
                        onChange={(number) =>
                            mutatePlatformSetting({
                                field: "max_article_age_months",
                                value: number,
                            })
                        }
                    />
                </Stack>
                <Stack
                    justifyItems="space-between"
                    direction="row"
                    alignItems="baseline"
                >
                    <Tooltip
                        placement="top"
                        title="The maximum number of days to retain records on workers' addition of companies"
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{ paddingRight: "40px" }}
                        >
                            Company Service Operations
                        </Typography>
                    </Tooltip>
                    <PlatformSettingsNumberField
                        unit="Days"
                        label="Time"
                        value={
                            platformSettingsData?.max_company_service_operations_age_days ||
                            0
                        }
                        onChange={(number) =>
                            mutatePlatformSetting({
                                field: "max_company_service_operations_age_days",
                                value: number,
                            })
                        }
                    />
                </Stack>
                <Stack
                    justifyItems="space-between"
                    direction="row"
                    alignItems="baseline"
                >
                    <Tooltip
                        placement="top"
                        title="The maximum number of days to retain records on workers' addition of articles"
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{ paddingRight: "62px" }}
                        >
                            Article Service Operations
                        </Typography>
                    </Tooltip>
                    <PlatformSettingsNumberField
                        unit="Days"
                        label="Time"
                        value={
                            platformSettingsData?.max_article_service_operations_age_days ||
                            0
                        }
                        onChange={(number) =>
                            mutatePlatformSetting({
                                field: "max_article_service_operations_age_days",
                                value: number,
                            })
                        }
                    />
                </Stack>
                <Stack
                    justifyItems="space-between"
                    direction="row"
                    alignItems="baseline"
                >
                    <Tooltip
                        placement="top"
                        title="The maximum number of days to retain records on service activity logs"
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{ paddingRight: "119px" }}
                        >
                            Service Activity Logs
                        </Typography>
                    </Tooltip>
                    <PlatformSettingsNumberField
                        unit="Days"
                        label="Time"
                        value={
                            platformSettingsData?.max_service_activity_logs_age_days ||
                            0
                        }
                        onChange={(number) =>
                            mutatePlatformSetting({
                                field: "max_service_activity_logs_age_days",
                                value: number,
                            })
                        }
                    />
                </Stack>
            </PlatformSettingsTitle>
            <PlatformSettingsTitle
                title="Primary Companies"
                subtitle="Enables or disables the concept of primary companies. E.g. Alphabet is a primary company and Google is one of its child companies"
                value={platformSettingsData?.primary_companies_enabled || false}
                loading={isLoading}
                onChange={(checked) =>
                    mutatePlatformSetting({
                        field: "primary_companies_enabled",
                        value: checked,
                    })
                }
            />
            <PlatformSettingsTitle
                title="Display Articles with no Company Link"
                subtitle="Enables or disables the ability for the news flow page to display articles that are not linked to a company"
                value={
                    platformSettingsData?.independent_articles_news_flow_enabled ||
                    false
                }
                loading={isLoading}
                onChange={(checked) =>
                    mutatePlatformSetting({
                        field: "independent_articles_news_flow_enabled",
                        value: checked,
                    })
                }
            />
            <PlatformSettingsTitle
                title="News flow - Article Summary Length"
                subtitle="The number of characters to store of an article for display in an article modal when viewing its summary"
                loading={isLoading}
            >
                <PlatformSettingsNumberField
                    unit="Character Length"
                    label="Length"
                    labelPlacement="start"
                    value={platformSettingsData?.article_summary_length || 0}
                    onChange={(number) =>
                        mutatePlatformSetting({
                            field: "article_summary_length",
                            value: number,
                        })
                    }
                />
            </PlatformSettingsTitle>
        </>
    );
};

export default PlatformSettings;
